<template>
  <div>
    <v-dialog
      v-if="item.status.name === 'Won'"
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          small
          color="success"
          class="success--text  font-weight-bold  pulse-green v-chip-light-bg"
          v-on="on"
        >
          {{ item.status.name }}
        </v-chip>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Dummy Order Test
        </v-card-title>

        <v-card-text class="mt-4">
          <v-row
            dense
            class="d-flex justify-between"
          >
            <v-col>
              <span class="d-flex flex-row align-center font-weight-bold">
                Platform <v-chip
                  class="ml-2"
                  color="#FFA726"
                  small
                >Shopee</v-chip></span>
            </v-col>
            <v-col class="d-flex flex-row align-center ">
              <div class=" d-flex flex-column">
                <p class="font-weight-bold mb-0">
                  Order ID
                </p>
                <p
                  class="font-weight-regular mb-0"
                >
                  HRION89202UIOND
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <div class=" d-flex flex-column">
                <p class="font-weight-bold mb-0">
                  Total Order
                </p>
                <p
                  class="font-weight-regular"
                >
                  {{ item.value }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Kembali
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-chip
      v-if="item.status.name === 'Lost'"
      small
      color="error"
      class="error--text  font-weight-bold  v-chip-light-bg"
    >
      {{ item.status.name }}
    </v-chip>
    <v-chip
      v-if="item.status.name === 'Cold'"
      small
      color="info"
      class="info--text  font-weight-bold  v-chip-light-bg"
    >
      {{ item.status.name }}
    </v-chip>
    <v-chip
      v-if="item.status.name === 'Hot'"
      small
      color="warning"
      class="warning--text font-weight-bold v-chip-light-bg"
    >
      {{ item.status.name }}
    </v-chip>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  data() {
    return {
      dialog: false,
    }
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/pulse.scss';
@include pulseAnimation("green",12px, #6dd672)
</style>
