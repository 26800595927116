export const headers = [
  {
    text: 'Nama',
    value: 'name',
  },
  {
    text: 'Status',
    value: 'status',
  },
  {
    text: 'Pelanggan',
    value: 'customer',
  },
  {
    text: 'Nilai Prospek',
    value: 'value',
  },
  {
    text: 'Tugas Prospek',
  },
  {
    text: 'Terakhir Diperbarui',
    value: 'updated_at',
  },
  {
    text: 'Pembuat',
    value: 'created_by',
  },
  {
    text: 'Mengikuti',
    value: 'is_subscribed',
  },
  {
    text: '',
    value: 'action',
  },
]
export const dateConverter = data => data.map(value => ({ ...value, created_at: `${new Date(value.created_at).toLocaleDateString('id-ID')} ${new Date(value.created_at).toLocaleTimeString('id-ID').replaceAll('.', ':')}`, updated_at: `${new Date(value.updated_at).toLocaleDateString('id-ID')} ${new Date(value.updated_at).toLocaleTimeString('id-ID').replaceAll('.', ':')}` }))
export const sortSelect = [
  {
    id: 0,
    name: 'Rank',
    value: 'lexorank',
    type: 'ASC',
  },
  {
    id: 1,
    name: 'Terakhir Diupdate',
    value: 'updated_at',
    type: 'DESC',
  },
  {
    id: 2,
    name: 'Terakhir Diupdate',
    value: 'updated_at',
    type: 'ASC',
  },
  {
    id: 3,
    name: 'Nama Prospek',
    value: 'name',
    type: 'DESC',
  },
  {
    id: 4,
    name: 'Nama Prospek',
    value: 'name',
    type: 'ASC',
  },
  {
    id: 5,
    name: 'Waktu Dibuat',
    value: 'created_at',
    type: 'DESC',
  },
  {
    id: 6,
    name: 'Waktu Dibuat',
    value: 'created_at',
    type: 'ASC',
  },
]
