<template>
  <v-hover #default="{ hover }">
    <v-card
      :outlined="deletedProspect"
      class="mb-2"
    >
      <v-card-title class="subtitle-1 font-weight-semibold">
        <v-row dense>
          <v-col cols="7">
            <router-link
              v-if="!deletedProspect"
              :to="{ name: 'prospect-detail', params: { id: prospectData.id,isSubscribed: prospectData.data.is_subscribed } }"
            >
              <p class="primary--text mb-0">
                {{ prospectData.name }}
              </p>
            </router-link>
            <p
              v-else
              class="primary--text mb-0"
            >
              {{ prospectData.name }}
            </p>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-menu
              v-if="!deletedProspect"
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-slide-x-reverse-transition
                  hide-on-leave
                  mode="out-in"
                >
                  <v-icon
                    v-show="hover"
                    size="20px"
                    v-bind="attrs"
                    class="ml-auto"
                    v-on="on"
                  >
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-slide-x-reverse-transition>
              </template>
              <v-list>
                <v-list-item
                  v-if="$can('update', 'Prospect')"
                  @click="$refs.taskForm.show(prospectData.data.customer,null,prospectData.data)"
                >
                  <span class="text-subtitle-2">Buat Task</span>
                </v-list-item>
                <v-divider class="my-2" />
                <router-link
                  :to="{ name: 'prospect-detail', params: { id: (fromKanban ? prospectData.data.id : prospectData.id), isSubscribed: prospectData.data.is_subscribed } }"
                >
                  <v-list-item> Lihat Detail </v-list-item>
                </router-link>
                <v-list-item
                  v-if="$can('update', 'Prospect')"
                  @click="$refs.prospectForm.update(fromKanban ? prospectData.data.id : prospectData.id)"
                >
                  <span class="text-subtitle-2">Edit</span>
                </v-list-item>
                <v-list-item
                  v-if="$can('del', 'Prospect')"
                  @click="$emit('onArchive', fromKanban ? prospectData.data.id : prospectData.id)"
                >
                  Hapus
                </v-list-item>
                <v-divider class="my-2" />
                <v-list-item>
                  <span class="text-subtitle-2">Mengikuti</span>
                  <v-checkbox
                    class="ml-auto mr-n3"
                    :input-value="prospectData.data.is_subscribed"
                    @change="(value)=> toggleNotification(value,prospectData.data.id)"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu
              v-else
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-slide-x-reverse-transition
                  hide-on-leave
                  mode="out-in"
                >
                  <v-icon
                    v-show="hover"
                    size="20px"
                    v-bind="attrs"
                    class="ml-auto"
                    v-on="on"
                  >
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-slide-x-reverse-transition>
              </template>
              <v-list>
                <v-list-item
                  v-if="$can('del', 'Prospect')"
                  @click="$emit('onRestore', fromKanban ? prospectData.data.id : prospectData.id)"
                >
                  Pulihkan prospek
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-slide-x-reverse-transition
                  hide-on-leave
                  mode="out-in"
                >
                  <span
                    v-show="!hover"
                    v-bind="attrs"
                    class="caption"
                    v-on="on"
                  >{{
                    fromKanban ? prospectData.data.updated_at : prospectData.updated_at
                  }}</span>
                </v-slide-x-reverse-transition>
              </template>
              <span class="d-flex flex-column">
                <p class="mb-0 subtitle-2">
                  Dibuat : {{ fromKanban ? prospectData.data.created_at : prospectData.created_at }}
                </p>
                <p class="mb-0 subtitle-2">
                  Terakhir diperbarui : {{ fromKanban ? prospectData.data.updated_at : prospectData.updated_at }}
                </p>
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <PerfectScrollbar
          v-if="fromKanban ? prospectData.data.description : prospectData.description"
          class="ps-kanban-prospect-description mb-2"
        >
          <p class="text-caption">
            {{ fromKanban ? prospectData.data.description : prospectData.description }}
          </p>
        </PerfectScrollbar>
        <p class="mb-1 text-caption text--disabled">
          Pelanggan
        </p>
        <router-link
          class="d-flex align-center mb-1"
          :to="{
            name: 'customer-detail',
            params: { id: fromKanban ? prospectData.data.customer.id : prospectData.customer.id },
          }"
        >
          <p class="mb-0 text-caption font-medium primary--text">
            {{ fromKanban ? prospectData.data.customer.name : prospectData.customer.name }}
          </p>
        </router-link>
        <v-row>
          <v-col>
            <p class="mb-1 text-caption text--disabled">
              Dibuat oleh
            </p>
            <router-link
              class="d-flex align-center mb-1"
              :to="{
                name: 'user-detail',
                params: { id: fromKanban ? prospectData.data.created_by.id : prospectData.created_by.id },
              }"
            >
              <p class="mb-0 text-caption font-medium primary--text">
                {{ fromKanban ? prospectData.data.created_by.name : prospectData.created_by.name }}
              </p>
            </router-link>
          </v-col>
          <v-col
            v-if="deletedProspect"
            class="d-flex justify-end"
          >
            <Chip
              class="mr-4"
              :item="prospectData"
            />
          </v-col>
        </v-row>
        <div
          v-if="!deletedProspect"
          class="d-flex flex-row mt-2"
        >
          <limit-elements
            v-if="fromKanban ? prospectData.data.assignedTo.length : prospectData.assignedTo.length"
            :elements="fromKanban ? prospectData.data.assignedTo : prospectData.assignedTo"
            :limit="3"
            class="v-avatar-group mr-auto"
            :class="rootThemeClasses"
          >
            <template #default="el">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    size="36"
                    class="primary"
                    v-on="on"
                  >
                    <v-img
                      v-if="el.data.user.photo"
                      :src="el.data.user.photo"
                    />
                    <span
                      v-else
                      class="white--text"
                    >
                      {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
                    </span>
                  </v-avatar>
                </template>
                <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
              </v-tooltip>
            </template>
            <template #others="el">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    size="36"
                    class="primary mb-1"
                    v-on="on"
                  >
                    <v-img
                      v-if="el.data.user.photo"
                      :src="el.data.user.photo"
                    />
                    <span
                      v-else
                      class="white--text"
                    >
                      {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
                    </span>
                  </v-avatar>
                </template>
                <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
              </v-tooltip>
            </template>
            <template #others-activator="el">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    size="36"
                    color="white"
                    class="primary--text text-caption"
                    v-on="on"
                  >
                    <span>+{{ el.data.limit }}</span>
                  </v-avatar>
                </template>
                <span>Lihat User Lain</span>
              </v-tooltip>
            </template>
          </limit-elements>
          <v-menu
            v-model="showAssignForm"
            :close-on-content-click="false"
            left
          >
            <template v-slot:activator="menu">
              <v-tooltip top>
                <template v-slot:activator="tooltip">
                  <v-btn
                    v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                    text
                    outlined
                    small
                    rounded
                    color="primary"
                    class="ml-auto mt-1"
                    v-on="{ ...menu.on, ...tooltip.on }"
                    @click.stop
                  >
                    <v-icon size="18">
                      {{ icons.mdiAccountPlus }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ditugaskan ke</span>
              </v-tooltip>
            </template>
            <AssignObject
              ref="assignObject"
              :prospect-id="fromKanban ? prospectData.data.id : prospectData.assignedTo"
              :data="fromKanban ? prospectData.data.assignedTo : prospectData.assignedTo"
              :visibility-id="fromKanban ? prospectData.data.visibility.id : prospectData.visibility.id"
              @success="
                $emit('update')
                showAssignForm = false
              "
            />
          </v-menu>
        </div>
      </v-card-text>
      <ProspectForm ref="prospectForm" />
      <TaskForm ref="taskForm" />
    </v-card>
  </v-hover>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import {
  mdiAccountPlus, mdiDotsVertical, mdiCheckboxBlankOutline, mdiCheckboxMarked,
} from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import useVuetify from '@core/utils/vuetify'
import Vue from 'vue'
import TaskForm from '@/views/activity/task/TaskForm.vue'
import AssignObject from '@/components/inputs/AssignObject.vue'
import ProspectForm from '@/views/prospect/component/ProspectForm.vue'
import Chip from '@/views/prospect/component/Chip.vue'
import LimitElements from '@/components/misc/LimitElements.vue'
import { toggleNotificationReference } from '@/graphql/mutations'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'

export default defineComponent({
  components: {
    PerfectScrollbar, AssignObject, ProspectForm, Chip, LimitElements, TaskForm,
  },
  props: {
    prospectData: {
      type: Object,
      required: true,
    },
    fromKanban: {
      type: Boolean,
      default: false,
    },
    deletedProspect: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { rootThemeClasses } = useVuetify()
    const showAssignForm = ref(false)
    const toggleNotification = (value, id) => {
      apolloClient
        .mutate({
          mutation: toggleNotificationReference,
          variables: {
            is_subscribe: value,
            ticket_id: props.ticketId,
            prospect_id: id,
            task_id: props.taskId,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(() => {
          Vue.notify({
            title: 'Berhasil!',
            text: value
              ? 'Berhasil mengaktifkan notifikasi untuk komentar ini!'
              : 'Berhasil menonaktifkan notifikasi untuk komentar ini!',
          })
        })
      emit('update')
    }

    return {
      toggleNotification,
      rootThemeClasses,
      showAssignForm,
      avatarText,
      icons: {
        mdiAccountPlus,
        mdiDotsVertical,
        mdiCheckboxBlankOutline,
        mdiCheckboxMarked,
      },
    }
  },
})
</script>
<style>
.ps-kanban-prospect-description {
  max-height: 100px;
}
</style>
