import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mb-2",attrs:{"outlined":_vm.deletedProspect}},[_c(VCardTitle,{staticClass:"subtitle-1 font-weight-semibold"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"7"}},[(!_vm.deletedProspect)?_c('router-link',{attrs:{"to":{ name: 'prospect-detail', params: { id: _vm.prospectData.id,isSubscribed: _vm.prospectData.data.is_subscribed } }}},[_c('p',{staticClass:"primary--text mb-0"},[_vm._v(" "+_vm._s(_vm.prospectData.name)+" ")])]):_c('p',{staticClass:"primary--text mb-0"},[_vm._v(" "+_vm._s(_vm.prospectData.name)+" ")])],1),_c(VCol,{staticClass:"d-flex justify-end"},[(!_vm.deletedProspect)?_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VSlideXReverseTransition,{attrs:{"hide-on-leave":"","mode":"out-in"}},[_c(VIcon,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"ml-auto",attrs:{"size":"20px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c(VList,[(_vm.$can('update', 'Prospect'))?_c(VListItem,{on:{"click":function($event){return _vm.$refs.taskForm.show(_vm.prospectData.data.customer,null,_vm.prospectData.data)}}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Buat Task")])]):_vm._e(),_c(VDivider,{staticClass:"my-2"}),_c('router-link',{attrs:{"to":{ name: 'prospect-detail', params: { id: (_vm.fromKanban ? _vm.prospectData.data.id : _vm.prospectData.id), isSubscribed: _vm.prospectData.data.is_subscribed } }}},[_c(VListItem,[_vm._v(" Lihat Detail ")])],1),(_vm.$can('update', 'Prospect'))?_c(VListItem,{on:{"click":function($event){return _vm.$refs.prospectForm.update(_vm.fromKanban ? _vm.prospectData.data.id : _vm.prospectData.id)}}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Edit")])]):_vm._e(),(_vm.$can('del', 'Prospect'))?_c(VListItem,{on:{"click":function($event){return _vm.$emit('onArchive', _vm.fromKanban ? _vm.prospectData.data.id : _vm.prospectData.id)}}},[_vm._v(" Hapus ")]):_vm._e(),_c(VDivider,{staticClass:"my-2"}),_c(VListItem,[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Mengikuti")]),_c(VCheckbox,{staticClass:"ml-auto mr-n3",attrs:{"input-value":_vm.prospectData.data.is_subscribed},on:{"change":function (value){ return _vm.toggleNotification(value,_vm.prospectData.data.id); }}})],1)],1)],1):_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VSlideXReverseTransition,{attrs:{"hide-on-leave":"","mode":"out-in"}},[_c(VIcon,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"ml-auto",attrs:{"size":"20px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c(VList,[(_vm.$can('del', 'Prospect'))?_c(VListItem,{on:{"click":function($event){return _vm.$emit('onRestore', _vm.fromKanban ? _vm.prospectData.data.id : _vm.prospectData.id)}}},[_vm._v(" Pulihkan prospek ")]):_vm._e()],1)],1),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VSlideXReverseTransition,{attrs:{"hide-on-leave":"","mode":"out-in"}},[_c('span',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!hover),expression:"!hover"}],staticClass:"caption"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.fromKanban ? _vm.prospectData.data.updated_at : _vm.prospectData.updated_at))])])]}}],null,true)},[_c('span',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"mb-0 subtitle-2"},[_vm._v(" Dibuat : "+_vm._s(_vm.fromKanban ? _vm.prospectData.data.created_at : _vm.prospectData.created_at)+" ")]),_c('p',{staticClass:"mb-0 subtitle-2"},[_vm._v(" Terakhir diperbarui : "+_vm._s(_vm.fromKanban ? _vm.prospectData.data.updated_at : _vm.prospectData.updated_at)+" ")])])])],1)],1)],1),_c(VCardText,[(_vm.fromKanban ? _vm.prospectData.data.description : _vm.prospectData.description)?_c('PerfectScrollbar',{staticClass:"ps-kanban-prospect-description mb-2"},[_c('p',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.fromKanban ? _vm.prospectData.data.description : _vm.prospectData.description)+" ")])]):_vm._e(),_c('p',{staticClass:"mb-1 text-caption text--disabled"},[_vm._v(" Pelanggan ")]),_c('router-link',{staticClass:"d-flex align-center mb-1",attrs:{"to":{
          name: 'customer-detail',
          params: { id: _vm.fromKanban ? _vm.prospectData.data.customer.id : _vm.prospectData.customer.id },
        }}},[_c('p',{staticClass:"mb-0 text-caption font-medium primary--text"},[_vm._v(" "+_vm._s(_vm.fromKanban ? _vm.prospectData.data.customer.name : _vm.prospectData.customer.name)+" ")])]),_c(VRow,[_c(VCol,[_c('p',{staticClass:"mb-1 text-caption text--disabled"},[_vm._v(" Dibuat oleh ")]),_c('router-link',{staticClass:"d-flex align-center mb-1",attrs:{"to":{
              name: 'user-detail',
              params: { id: _vm.fromKanban ? _vm.prospectData.data.created_by.id : _vm.prospectData.created_by.id },
            }}},[_c('p',{staticClass:"mb-0 text-caption font-medium primary--text"},[_vm._v(" "+_vm._s(_vm.fromKanban ? _vm.prospectData.data.created_by.name : _vm.prospectData.created_by.name)+" ")])])],1),(_vm.deletedProspect)?_c(VCol,{staticClass:"d-flex justify-end"},[_c('Chip',{staticClass:"mr-4",attrs:{"item":_vm.prospectData}})],1):_vm._e()],1),(!_vm.deletedProspect)?_c('div',{staticClass:"d-flex flex-row mt-2"},[(_vm.fromKanban ? _vm.prospectData.data.assignedTo.length : _vm.prospectData.assignedTo.length)?_c('limit-elements',{staticClass:"v-avatar-group mr-auto",class:_vm.rootThemeClasses,attrs:{"elements":_vm.fromKanban ? _vm.prospectData.data.assignedTo : _vm.prospectData.assignedTo,"limit":3},scopedSlots:_vm._u([{key:"default",fn:function(el){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"primary",attrs:{"size":"36"}},'v-avatar',attrs,false),on),[(el.data.user.photo)?_c(VImg,{attrs:{"src":el.data.user.photo}}):_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.avatarText(((el.data.user.first_name) + " " + (el.data.user.last_name))))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(el.data.user.first_name)+" "+_vm._s(el.data.user.last_name))])])]}},{key:"others",fn:function(el){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"primary mb-1",attrs:{"size":"36"}},'v-avatar',attrs,false),on),[(el.data.user.photo)?_c(VImg,{attrs:{"src":el.data.user.photo}}):_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.avatarText(((el.data.user.first_name) + " " + (el.data.user.last_name))))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(el.data.user.first_name)+" "+_vm._s(el.data.user.last_name))])])]}},{key:"others-activator",fn:function(el){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"primary--text text-caption",attrs:{"size":"36","color":"white"}},'v-avatar',attrs,false),on),[_c('span',[_vm._v("+"+_vm._s(el.data.limit))])])]}}],null,true)},[_c('span',[_vm._v("Lihat User Lain")])])]}}],null,true)}):_vm._e(),_c(VMenu,{attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-auto mt-1",attrs:{"text":"","outlined":"","small":"","rounded":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',Object.assign({}, menu.attrs, tooltip.attrs),false),Object.assign({}, menu.on, tooltip.on)),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountPlus)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ditugaskan ke")])])]}}],null,true),model:{value:(_vm.showAssignForm),callback:function ($$v) {_vm.showAssignForm=$$v},expression:"showAssignForm"}},[_c('AssignObject',{ref:"assignObject",attrs:{"prospect-id":_vm.fromKanban ? _vm.prospectData.data.id : _vm.prospectData.assignedTo,"data":_vm.fromKanban ? _vm.prospectData.data.assignedTo : _vm.prospectData.assignedTo,"visibility-id":_vm.fromKanban ? _vm.prospectData.data.visibility.id : _vm.prospectData.visibility.id},on:{"success":function($event){_vm.$emit('update')
              _vm.showAssignForm = false}}})],1)],1):_vm._e()],1),_c('ProspectForm',{ref:"prospectForm"}),_c('TaskForm',{ref:"taskForm"})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }