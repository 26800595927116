<template>
  <div>
    <TableView
      :data="tabs"
      :prospect-list="prospectList"
      :prospect-count="prospectCount"
      @onFilterChange="onFilterChange"
    />
    <KanbanView
      :data="tabs"
      :prospect-kanban-list="prospectKanbanList"
      @onFilterChange="onFilterChange"
      @onScrollKanban="onScrollKanban"
    />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import TableView from '@/views/prospect/component/TableView.vue'
import KanbanView from '@/views/prospect/component/KanbanView.vue'

export default defineComponent({
  props: {
    tabs: {
      default: () => {},
      type: Object,
    },
    type: {
      default: () => {},
      type: Object,
    },
    prospectList: {
      type: Array,
      default: () => [],
    },
    prospectKanbanList: {
      type: Array,
      default: () => [],
    },
    prospectCount: {
      type: Number,
      default: 0,
    },
  },
  components: {
    TableView,
    KanbanView,
  },
  setup(props, { emit }) {
    const onFilterChange = () => {
      emit('filterChange')
    }

    const onScrollKanban = data => {
      emit('onScrollKanban', data)
    }

    return {
      onFilterChange,
      onScrollKanban,
    }
  },
})
</script>
