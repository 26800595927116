<template>
  <div>
    <v-card>
      <v-row
        class="mx-2 pt-2"
      >
        <v-col
          cols="12"
          sm="4"
        >
          <h3>Daftar Prospek</h3>
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="ms-0 ms-md-auto d-flex justify-space-between"
        >
          <v-btn-toggle
            v-model="viewTab"
            mandatory
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiTable }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Tabel</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCardText }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Kartu</span>
            </v-tooltip>
          </v-btn-toggle>
          <v-tooltip
            v-if="$can('create', 'Prospect')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ms-auto ms-md-4"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$refs.prospectForm.show()"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <span>Tambah Prospek</span>
          </v-tooltip>
          <v-menu
            v-model="isMenuOpen"
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8px"
            nudge-left="20px"
            transition="slide-y-reverse-transition"
          >
            <template v-slot:activator="menu">
              <v-tooltip bottom>
                <template v-slot:activator="tooltip">
                  <v-icon
                    size="26"
                    class="mt-2 ml-4"
                    v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                    v-on="{ ...menu.on, ...tooltip.on }"
                    @click.stop
                  >
                    {{ icons.mdiArchive }}
                  </v-icon>
                </template>
                <span>Prospek dihapus / diarsipkan</span>
              </v-tooltip>
            </template>
            <ArchivedProspect
              :is-open="isMenuOpen"
              @onUpdate="fetchProspect"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-tabs
        v-model="currentTab"

        show-arrows
      >
        <v-tab>
          Semua
        </v-tab>
        <v-tab>
          Privat
        </v-tab>
        <v-tab>
          Mengikuti
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="currentTab"
        touchless
      >
        <div style="height: 4px">
          <v-progress-linear
            v-show="loadingProspect"
            indeterminate
            color="primary"
          />
        </div>
        <v-tab-item>
          <v-tabs-items
            v-model="viewTab"
            touchless
          >
            <ProspectTabs
              :prospect-list="prospectList"
              :prospect-kanban-list="prospectKanbanList"
              :prospect-count="prospectCount"
              @filterChange="refetch"
              @onScrollKanban="onScrollKanban"
            />
          </v-tabs-items>
        </v-tab-item>
        <v-tab-item>
          <v-tabs-items
            v-model="viewTab"
            touchless
          >
            <ProspectTabs
              :prospect-list="prospectList"
              :prospect-kanban-list="prospectKanbanList"
              :prospect-count="prospectCount"
              @filterChange="refetch"
              @onScrollKanban="onScrollKanban"
            />
          </v-tabs-items>
        </v-tab-item>
        <v-tab-item>
          <v-tabs-items
            v-model="viewTab"
            touchless
          >
            <ProspectTabs
              :prospect-list="prospectList"
              :prospect-kanban-list="prospectKanbanList"
              :prospect-count="prospectCount"
              @filterChange="refetch"
              @onScrollKanban="onScrollKanban"
            />
          </v-tabs-items>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <ProspectForm ref="prospectForm" />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  mdiPlus, mdiClose, mdiTable, mdiCardText, mdiArchive,
} from '@mdi/js'
import { useStorage } from '@vueuse/core'
import { createFieldMapper } from 'vuex-use-fields'
import ProspectForm from '@/views/prospect/component/ProspectForm.vue'
import ProspectTabs from '@/views/prospect/component/ProspectTabs.vue'
import ArchivedProspect from '@/views/prospect/component/ArchivedProspect.vue'
import useProspect from '@/composables/useProspect'
import { prospectSubscription } from '@/graphql/subscriptions'
import { apolloClient } from '@/vue-apollo'
import errorHandling from '@/utils/errorHandling'

const useFieldProspect = createFieldMapper({ getter: 'prospect/getField', setter: 'prospect/setField' })
export default {
  name: 'Prospect',
  components: {
    ProspectForm,
    ProspectTabs,
    ArchivedProspect,
  },
  setup() {
    const isMenuOpen = ref(false)
    const state = {
      ...useFieldProspect(['filter', 'filterCount', 'pagination', 'prospectStatusData']),
    }
    const {
      prospectStatusData, fetchProspectStatus, prospectPagination, prospectKanbanPagination, fetchProspect, fetchProspectsKanban, prospectList, prospectKanbanList, prospectCount, prospectFilter, loadingProspect, listenScrollFetchMoreProspect,
    } = useProspect()
    const refetch = () => {
      prospectFilter.value = state.filter.value
      prospectPagination.value = state.pagination.value
      fetchProspect()
      fetchProspectsKanban()
    }
    onMounted(() => {
      prospectFilter.value = state.filter.value
      prospectPagination.value = state.pagination.value
      fetchProspectStatus(() => {
        state.prospectStatusData.value = prospectStatusData.value
      })
      fetchProspect(false, false, () => {})
      fetchProspectsKanban(false, false, () => {})
    })

    const onScrollKanban = data => {
      listenScrollFetchMoreProspect(data)
    }

    const viewTab = useStorage('viewTabProspect', 0)
    const currentTab = ref(0)
    const tabs = {
      view: viewTab.value,
      current: currentTab.value,
    }

    const observer = apolloClient.subscribe({
      query: prospectSubscription,
      fetchPolicy: 'no-cache',
    })

    observer.subscribe({
      next(sub) {
        console.log('receiveProspect', sub.data.prospectSubscription)
        fetchProspectsKanban(false, false, () => {}, prospectKanbanPagination.value.offset)
      },
      error(error) {
        console.error(error)
        errorHandling(error)
      },
    })

    return {
      refetch,
      onScrollKanban,
      loadingProspect,
      currentTab,
      viewTab,
      tabs,
      prospectList,
      prospectKanbanList,
      prospectCount,
      fetchProspect,
      fetchProspectsKanban,
      listenScrollFetchMoreProspect,
      isMenuOpen,
      icons: {
        mdiPlus,
        mdiClose,
        mdiTable,
        mdiCardText,
        mdiArchive,
      },
    }
  },
}
</script>
