<template>
  <v-tab-item>
    <v-row
      dense
      class="px-4 pt-4"
    >
      <v-col
        cols="12"
        lg="3"
        md="4"
      >
        <v-text-field
          v-model="state.filter.value.search"
          class="mb-n3"
          dense
          clearable
          outlined
          label="Cari prospek"
          type="text"

          @input="onSearch "
          @click:clear="clearSearch"
        />
      </v-col>
      <v-spacer />

      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="state.sort.value.id"
          name="name"
          placeholder="Sortir Berdasarkan"
          :items="sortSelect"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          return-object
          @input="inputSort"
        >
          <template #item="{ item }">
            <div class="d-flex w-full py-2">
              <v-icon
                size="18"
                class="mr-2"
              >
                {{ item.type === 'ASC' ? icons.mdiSortAscending : icons.mdiSortDescending }}
              </v-icon>
              <span>{{ item.name }}</span>
            </div>
          </template>
          <template #selection="{ item }">
            <div class="d-flex w-full py-2">
              <v-icon
                size="18"
                class="mr-2"
              >
                {{ item.type === 'ASC' ? icons.mdiSortAscending : icons.mdiSortDescending }}
              </v-icon>
              <span>{{ item.name }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        md="2"
      >
        <v-badge
          :value="state.filterCount.value"
          bordered
          overlap
          class="w-100 cursor-pointer"
          @click.native="clearFilter"
        >
          <template #badge>
            <v-icon>
              {{ icons.mdiClose }}
            </v-icon>
          </template>
          <v-btn
            :class="$vuetify.breakpoint.mdAndDown && 'mb-5'"
            :loading="state.prospectLoading.value"
            outlined
            block
            large
            :color="state.filterCount.value > 0 ? 'primary' : null"
            @click.stop="$refs.filterProspect.show()"
          >
            Filter {{ state.filterCount.value ? `(${state.filterCount.value})` : '' }}
          </v-btn>
        </v-badge>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="computedProspectList ? computedProspectList : []"
      :items-per-page="5"
      item-key="id"
      disable-sort
      disable-filtering
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Jumlah data',
      }"
      :server-items-length=" computedProspectCount "
      mobile-breakpoint="0"
      :loading="state.prospectLoading.value"
      :options.sync="pagination"
    >
      <template v-slot:[`item.name`]="props">
        <div class="d-flex flex-column py-2">
          <router-link :to="{ name: 'prospect-detail', params: { id: props.item.id,isSubscribed: props.item.is_subscribed} }">
            <span class="primary--text font-medium">{{ props.item.name }}</span>
          </router-link>
          <p
            v-if="props.item.description"
            class="d-inline-block text-truncate mb-0 caption"
            style="max-width: 180px;"
          >
            {{ props.item.description }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.status`]="props">
        <div>
          <Chip
            v-if="props.item"
            :item="props.item"
          />
        </div>
      </template>
      <template v-slot:[`item.customer`]="props">
        <router-link
          class="d-flex align-center"
          :to="{ name: 'customer-detail', params: { id: props.item.customer.id} }"
        >
          <v-avatar
            size="32"
            color="primary"
          >
            <span class="white--text">{{ avatarText(props.item.customer.name) }}</span>
          </v-avatar>

          <p class="mb-0 px-2 primary--text font-medium">
            {{ props.item.customer.name }}
          </p>
        </router-link>
      </template>
      <template v-slot:[`item.updated_at`]="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >{{ props.item.updated_at }}</span>
          </template>
          <span class="d-flex flex-column">
            <p class="mb-0 subtitle-2">
              Dibuat : {{ props.item.created_at }}
            </p>
            <p class="mb-0 subtitle-2">
              Terakhir diperbarui : {{ props.item.updated_at }}
            </p>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.created_by`]="props">
        <router-link
          class="d-flex align-center"
          :to="{ name: 'user-detail', params: { id: props.item.created_by.id } }"
        >
          <v-avatar
            size="32"
            color="primary"
          >
            <span class="white--text">{{ avatarText(`${props.item.created_by.name}`) }}</span>
          </v-avatar>

          <p class="mb-0 px-2 primary--text font-medium">
            {{ props.item.created_by.name }}
          </p>
        </router-link>
      </template>
      <template v-slot:[`item.is_subscribed`]="props">
        <div class="d-flex align-center">
          <v-checkbox
            :input-value="props.item.is_subscribed"
            @change="(value)=> toggleNotification(value,props.item.id)"
          />
        </div>
      </template>
      <template v-slot:[`item.action`]="props">
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="20px"
              v-bind="attrs"
              class="ml-auto"
              v-on="on"
            >
              {{ icons.mdiDotsVertical }}
            </v-icon>
          </template>
          <v-list>
            <v-list-item
              v-if="$can('update', 'Prospect')"
              @click="$refs.taskForm.show( props.item.customer, null, props.item )"
            >
              <span class="text-subtitle-2">Buat Task</span>
            </v-list-item>
            <v-divider class="my-2" />
            <router-link :to="{ name: 'prospect-detail', params: { id: props.item.id } }">
              <v-list-item> Lihat Detail</v-list-item>
            </router-link>
            <v-divider class="my-2" />
            <v-list-item
              v-if="$can('update', 'Prospect')"
              v-show=" props.item.status.name !== 'Won' && props.item.status.name !== 'Lost'"
              @click="$refs.prospectForm.update(props.item.id)"
            >
              Edit
            </v-list-item>
            <v-divider
              v-show=" props.item.status.name !== 'Won' && props.item.status.name !== 'Lost'"
              class="my-2"
            />
            <v-list-item
              v-if="$can('del', 'Prospect')"
              @click="archiveProspectFunc(props.item.id, true)"
            >
              Hapus
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template
        slot="footer.page-text"
        slot-scope="item"
      >
        Melihat

        <v-progress-circular
          v-if="state.prospectLoading.value"
          class="mx-2 mt-n1"
          indeterminate
          size="20"
        />
        <span v-else>
          {{ item.pageStart }} - {{ item.pageStop }}
        </span>
        dari total {{ item.itemsLength }} data
      </template>
    </v-data-table>
    <ProspectForm ref="prospectForm" />
    <TaskForm ref="taskForm" />
    <FilterProspect
      ref="filterProspect"
      :filter-data="state.filter"
      @filter="onFilter"
    />
  </v-tab-item>
</template>
<script>
import {
  defineComponent, ref, watch, computed,
} from '@vue/composition-api'
import {
  mdiDotsVertical, mdiMagnify,

  mdiPlus, mdiClose, mdiTable, mdiCardText, mdiSortDescending, mdiSortAscending,
} from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import { useDebounceFn } from '@vueuse/core'
import { createFieldMapper } from 'vuex-use-fields'
import Vue from 'vue'
import { headers, sortSelect } from '../variable'
import TaskForm from '@/views/activity/task/TaskForm.vue'
import Chip from '@/views/prospect/component/Chip.vue'
import ProspectForm from '@/views/prospect/component/ProspectForm.vue'
import FilterProspect from '@/views/prospect/component/FilterProspect.vue'
import { apolloClient } from '@/vue-apollo'
import { archiveProspect, toggleNotificationReference } from '@/graphql/mutations'

import store from '@/store'

const useFieldProspect = createFieldMapper({ getter: 'prospect/getField', setter: 'prospect/setField' })
export default defineComponent({
  components: {
    Chip,
    ProspectForm,
    FilterProspect,
    TaskForm,
  },
  props: {
    loading: {
      default: true,
      type: Boolean,
    },
    types: {
      type: String,
      default: 'All',
    },
    prospectList: {
      type: Array,
      default: () => [],
    },
    prospectCount: {
      type: Number,
      default: 0,
    },
    filterCount: {
      type: Number,
      default: 0,
    },
    isSubscribed: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const state = {
      ...useFieldProspect(['filter', 'filterCount', 'pagination', 'prospectList', 'prospectCount', 'prospectLoading', 'sort']),
    }
    const search = ref('')
    const sortData = ref({})
    const selectedOrder = ref(0)
    const activeFilter = ref({})
    const selectedRows = ref([])
    const pagination = ref({})
    const filterModal = ref(false)
    const filterProspect = ref(null)
    const computedProspectList = computed(() => props.prospectList)
    const computedProspectCount = computed(() => props.prospectCount)

    /* watch(() => props.prospectList, () => {
      computedProspectList.value = props.prospectList
      computedProspectCount.value = props.prospectCount
      // console.log(computedProspectList.value)
    }) */
    watch(pagination, (val, oldVal) => {
      if (!oldVal.itemsPerPage) {
        return
      }
      pagination.value = val
      const paginationData = {
        limit: pagination.value.itemsPerPage ?? 10,
        // eslint-disable-next-line no-nested-ternary
        offset: (pagination.value.page === 1 ? 0 : (pagination.value.page - 1) * pagination.value.itemsPerPage),
      }
      state.pagination.value = paginationData
      emit('onFilterChange')
    })
    const inputSort = data => {
      sortData.value = data
      activeFilter.value = { ...activeFilter.value, sort: data.value, order: data.type }
      state.filter.value = activeFilter.value
      emit('onFilterChange')
    }
    const onSearch = useDebounceFn(data => {
      activeFilter.value = { ...activeFilter.value, search: data }
      state.filter.value = activeFilter.value
      emit('onFilterChange')
    }, 1000)
    const clearSearch = () => {
      activeFilter.value = { ...activeFilter.value, search: undefined }
      state.filter.value = activeFilter.value
      emit('onFilterChange', { paging: pagination.value, filter: activeFilter.value })
    }
    // eslint-disable-next-line no-return-assign
    const clearFilter = () => {
      filterProspect.value.resetFilter()
    }
    const toggleNotification = (value, id) => {
      apolloClient.mutate({
        mutation: toggleNotificationReference,
        variables: {
          is_subscribe: value,
          job_id: props.jobId,
          prospect_id: id,
          task_id: props.taskId,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(() => {
        Vue.notify({
          title: 'Berhasil!',
          text: value
            ? 'Berhasil mengaktifkan notifikasi untuk komentar ini!'
            : 'Berhasil menonaktifkan notifikasi untuk komentar ini!',
        })
      })
      emit('onFilterChange')
    }
    const onFilter = filter => {
      activeFilter.value = { ...activeFilter.value, ...filter.data }
      state.filter.value = activeFilter.value
      state.filterCount.value = filter.count
      emit('onFilterChange')
    }
    const archiveProspectFunc = id => {
      Vue.$dialog({
        title: 'Hapus Prospek?',
        body: 'Yakin ingin hapus Prospek?',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: archiveProspect,
            fetchPolicy: 'no-cache',
            variables: {
              id,
              is_archive: true,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menghapus prospek!',
            })
            emit('onFilterChange')
          })
        }
      })
    }

    return {
      archiveProspectFunc,
      toggleNotification,
      state,
      search,
      selectedRows,
      clearFilter,
      pagination,
      avatarText,
      headers,
      onSearch,
      sortSelect,
      inputSort,
      selectedOrder,
      clearSearch,
      filterModal,
      filterProspect,
      onFilter,
      computedProspectList,
      computedProspectCount,
      icons: {
        mdiPlus,
        mdiClose,
        mdiTable,
        mdiCardText,
        mdiDotsVertical,
        mdiMagnify,
        mdiSortDescending,
        mdiSortAscending,
      },
    }
  },
})
</script>
