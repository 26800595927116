<template>
  <v-tab-item class="mx-4">
    <v-row
      dense
      class="px-4 pt-4 mb-2"
    >
      <v-col
        cols="12"
        lg="3"
        md="4"
      >
        <v-text-field
          v-model="state.filter.value.search"
          class="mb-n3"
          dense
          clearable
          outlined
          label="Cari prospek"
          type="text"

          @input="onSearch "
          @click:clear="clearSearch"
        />
      </v-col>
      <v-spacer />

      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="state.sort.value.id"
          name="name"
          placeholder="Sortir Berdasarkan"
          :items="sortSelect"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          return-object
          @input="inputSort"
        >
          <template #item="{ item }">
            <div class="d-flex w-full py-2">
              <v-icon
                size="18"
                class="mr-2"
              >
                {{ item.type === 'ASC' ? icons.mdiSortAscending : icons.mdiSortDescending }}
              </v-icon>
              <span>{{ item.name }}</span>
            </div>
          </template>
          <template #selection="{ item }">
            <div class="d-flex w-full py-2">
              <v-icon
                size="18"
                class="mr-2"
              >
                {{ item.type === 'ASC' ? icons.mdiSortAscending : icons.mdiSortDescending }}
              </v-icon>
              <span>{{ item.name }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        md="2"
      >
        <v-badge
          :value="state.filterCount.value"
          bordered
          overlap
          class="w-100 cursor-pointer"
          @click.native="clearFilter"
        >
          <template #badge>
            <v-icon>
              {{ icons.mdiClose }}
            </v-icon>
          </template>
          <v-btn
            :class="$vuetify.breakpoint.mdAndDown && 'mb-5'"
            :loading="state.prospectLoading.value"
            outlined
            block
            large
            :color="state.filterCount.value > 0 ? 'primary' : null"
            @click.stop="$refs.filterProspect.show()"
          >
            Filter {{ state.filterCount.value ? `(${state.filterCount.value})` : '' }}
          </v-btn>
        </v-badge>
      </v-col>
    </v-row>
    <KanbanBoard
      v-if="!state.prospectLoading.value"
      id="kanbanboard"
      v-scroll:#kanbanboard="onScrollKanban"
      :value="kanbanList"
      :sortable="false"
      @moveStatus="handleUpdateStatus"
      @sortColumn="handleSortColumn"
    >
      <template
        slot="kanban-card"
        slot-scope="props"
      >
        <ProspectCard
          :prospect-data="props.data"
          :from-kanban="true"
          @onArchive="archiveProspectFunc"
          @update="$emit('update')"
        />
      </template>
    </KanbanBoard>
    <v-row v-else>
      <v-col>
        <v-skeleton-loader
          type="article"
        />
      </v-col>
      <v-col>
        <v-skeleton-loader
          type="article"
        />
      </v-col>
      <v-col>
        <v-skeleton-loader
          type="article"
        />
      </v-col>
      <v-col>
        <v-skeleton-loader
          type="article"
        />
      </v-col>
    </v-row>
    <FilterProspect
      ref="filterProspect"
      :filter-data="state.filter"
      @filter="onFilter"
    />
  </v-tab-item>
</template>
<script>
import {
  computed,
  defineComponent, onMounted, ref, watch,
} from '@vue/composition-api'
import {
  mdiDotsVertical, mdiSortDescending,
  mdiSortAscending, mdiClose,
} from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import { avatarText } from '@core/utils/filter'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import Vue from 'vue'
import { createFieldMapper } from 'vuex-use-fields'
import { headers, sortSelect } from '../variable'
import KanbanBoard from '@/components/misc/KanbanBoard.vue'
import FilterProspect from '@/views/prospect/component/FilterProspect.vue'
import ProspectCard from '@/views/prospect/component/ProspectCard.vue'
import { archiveProspect, updateProspectStatus } from '@/graphql/mutations'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { LexoRank } from 'lexorank'
import useLexorank from '@/composables/useLexorank'

const useFieldProspect = createFieldMapper({ getter: 'prospect/getField', setter: 'prospect/setField' })
export default defineComponent({
  components: {
    KanbanBoard,
    PerfectScrollbar,
    FilterProspect,
    ProspectCard,
  },
  props: {
    types: {
      type: String,
      default: 'All',
    },
    data: {
      type: Object,
      default: () => {},
    },
    prospectKanbanList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const state = {
      ...useFieldProspect(['filter', 'filterCount', 'pagination', 'prospectStatusData', 'prospectCount', 'prospectLoading', 'prospectStatusData', 'sort']),
    }
    const {
      updateObjectLexorank,
    } = useLexorank({
      objectType: 'prospect',
    })
    const search = ref('')
    const sortData = ref({})
    const selectedOrder = ref(0)
    const activeFilter = ref({})
    const pagination = ref({})
    const filterModal = ref(false)
    const filterProspect = ref(null)
    const kanbanList = ref([])
    const computedProspectKanbanList = computed(() => props.prospectKanbanList)
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    })

    const onScrollKanban = data => {
      emit('onScrollKanban', data)
    }

    const kanbanArr = () => {
      const arrangedPros = state.prospectStatusData.value.map((val, idx) => {
        const filteredProps = computedProspectKanbanList.value.filter(data => data.status.name === val.name)
        const arrangedProps = filteredProps.map(data => ({ id: data.id, name: data.name, data }))
        let color = ''
        if (val.name === 'Cold') color = '#16b1ff'
        else if (val.name === 'Won') color = '#56ca00'
        else if (val.name === 'Lost') color = '#ff4c51'
        else color = '#ffb400'

        return {
          id: idx,
          name: val.name,
          color,
          sortable: selectedOrder.value.id === 0 || selectedOrder.value === 0,
          lists: arrangedProps,
        }
      })
      kanbanList.value = arrangedPros
    }
    watch(computedProspectKanbanList, () => {
      kanbanArr()
    })

    onMounted(() => {
      kanbanArr()
    })

    /* watch(options, value => {
      pagination.value = value
      const paginationData = {
        limit: pagination.value.itemsPerPage ?? 10,
        // eslint-disable-next-line no-nested-ternary
        offset: (pagination.value.page === 1 ? 0 : (pagination.value.page - 1) * pagination.value.itemsPerPage),
      }
      state.pagination.value = paginationData
      console.log('kanban watch')
      emit('onFilterChange')
    }) */

    // const handleSortColumn = data => {
    //   console.log(data)
    //   if (selectedOrder.value === 0 || selectedOrder.value.id === 0) {
    //     const replacedData = data.rawData[data.index].lists[data.dragData.newIndex]
    //     const movedData = data.dragData.element
    //     console.log(replacedData)
    //     const replacedDataLexoRank = LexoRank.parse(replacedData.data.lexorank)
    //     let movedDataLexoRank = ''
    //     if (data.dragData.oldIndex >= data.dragData.newIndex) {
    //       const dataBefore = data.rawData[data.index].lists[data.dragData.newIndex - 1]
    //       if (dataBefore) movedDataLexoRank = replacedDataLexoRank.between(LexoRank.parse(dataBefore.data.lexorank))
    //       else movedDataLexoRank = replacedDataLexoRank.between(replacedDataLexoRank.genPrev())
    //     } else {
    //       const dataAfter = data.rawData[data.index].lists[data.dragData.newIndex + 1]
    //       if (dataAfter) movedDataLexoRank = replacedDataLexoRank.between(LexoRank.parse(dataAfter.data.lexorank))
    //       else movedDataLexoRank = replacedDataLexoRank.between(replacedDataLexoRank.genNext())
    //     }
    //     updateObjectLexorank(movedDataLexoRank.toString(), movedData.id, store.getters.getCurrentWorkspaceId)
    //     emit('update')
    //   }
    // }

    const handleSortColumn = data => {
      if (state.sort.value.value !== 'lexorank') {
        errorHandling('Drag and Drop hanya bisa digunakan pada mode urutan RANK')
      } else if (selectedOrder.value === 0 || selectedOrder.value.id === 0) {
        const dataAbove = data.rawData[data.index].lists[data.dragData.newIndex - 1]
        const dataBelow = data.rawData[data.index].lists[data.dragData.newIndex + 1]
        const movedData = data.dragData.element
        let movedDataLexorank = LexoRank.parse(movedData.data.lexorank)
        if (dataAbove && dataBelow) {
          const dataAboveLexorank = LexoRank.parse(dataAbove.data.lexorank)
          const dataBelowLexorank = LexoRank.parse(dataBelow.data.lexorank)
          movedDataLexorank = dataAboveLexorank.between(dataBelowLexorank)
        } else if (dataAbove) {
          const dataAboveLexorank = LexoRank.parse(dataAbove.data.lexorank)
          console.log('data', dataAboveLexorank, 'vs', movedDataLexorank)
          movedDataLexorank = dataAboveLexorank.genNext()
        } else if (dataBelow) {
          const dataBelowLexorank = LexoRank.parse(dataBelow.data.lexorank)
          console.log('data', dataBelowLexorank, 'vs', movedDataLexorank)
          movedDataLexorank = dataBelowLexorank.genPrev()
        }
        console.log('compare:', movedDataLexorank)
        updateObjectLexorank(movedDataLexorank.toString(), movedData.id, store.getters.getCurrentWorkspaceId)
        emit('update')
      }
    }

    const handleUpdateStatus = data => {
      if (state.sort.value.value !== 'lexorank') {
        errorHandling('Drag and Drop hanya bisa digunakan pada mode urutan RANK')
      } else {
        Vue.$dialog({
          title: 'Update Status?',
          body: `Yakin ingin update status ke ${state.prospectStatusData.value[data.index].name}?`,
        }).then(result => {
          if (result) {
            apolloClient.mutate({
              mutation: updateProspectStatus,
              variables: {
                id: data.dragData.element.id,
                status_id: state.prospectStatusData.value[data.index].id,
                workspace_id: store.getters.getCurrentWorkspaceId,
              },
            }).then(() => {
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil mengubah status prospek!',
              })
              handleSortColumn(data)
              emit('update')
            }).catch(err => {
              errorHandling(err)
            })
          }
        })
      }
    }
    const inputSort = data => {
      sortData.value = data
      activeFilter.value = { ...activeFilter.value, sort: data.value, order: data.type }
      state.filter.value = activeFilter.value
      emit('onFilterChange')
    }
    const onSearch = useDebounceFn(data => {
      activeFilter.value = { ...activeFilter.value, search: data }
      state.filter.value = activeFilter.value
      emit('onFilterChange')
    }, 1000)
    const clearSearch = () => {
      activeFilter.value = { ...activeFilter.value, search: undefined }
      state.filter.value = activeFilter.value
      emit('onFilterChange', { paging: pagination.value, filter: activeFilter.value })
    }
    // eslint-disable-next-line no-return-assign
    const clearFilter = () => {
      filterProspect.value.resetFilter()
    }
    const onFilter = filter => {
      activeFilter.value = { ...activeFilter.value, ...filter.data }
      state.filter.value = activeFilter.value
      state.filterCount.value = filter.count
      emit('onFilterChange')
    }

    const archiveProspectFunc = id => {
      Vue.$dialog({
        title: 'Hapus Prospek?',
        body: 'Yakin ingin hapus Prospek?',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: archiveProspect,
            fetchPolicy: 'no-cache',
            variables: {
              id,
              is_archive: true,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menghapus prospek!',
            })
            emit('update')
          }).catch(err => {
            errorHandling(err)
          })
        }
      })
    }

    return {
      handleSortColumn,
      archiveProspectFunc,
      computedProspectKanbanList,
      state,
      search,
      selectedOrder,
      activeFilter,
      headers,
      sortSelect,
      kanbanList,
      inputSort,
      clearSearch,
      onSearch,
      clearFilter,
      options,
      pagination,
      avatarText,
      onFilter,
      filterModal,
      filterProspect,
      handleUpdateStatus,
      onScrollKanban,
      LexoRank,
      icons: {
        mdiDotsVertical,
        mdiSortDescending,
        mdiSortAscending,
        mdiClose,
      },
    }
  },
})
</script>
<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
 .kanban-footer {
    position: fixed;
    bottom: 16px;
    right: 0;
    margin: 0 24px;
    z-index: 5;

    .kanban-pagination {
      .v-data-footer__select {
        margin-left: 0 !important;
      }

      .v-data-footer__pagination {
        margin-left: auto !important;
      }
    }
  }
  .kanban {
    @include style-scroll-bar();
    max-height: 100vh;
    overflow-y: auto;
  }

</style>
