import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTabItem,[_c(VRow,{staticClass:"px-4 pt-4",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","lg":"3","md":"4"}},[_c(VTextField,{staticClass:"mb-n3",attrs:{"dense":"","clearable":"","outlined":"","label":"Cari prospek","type":"text"},on:{"input":_vm.onSearch,"click:clear":_vm.clearSearch},model:{value:(_vm.state.filter.value.search),callback:function ($$v) {_vm.$set(_vm.state.filter.value, "search", $$v)},expression:"state.filter.value.search"}})],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VSelect,{attrs:{"name":"name","placeholder":"Sortir Berdasarkan","items":_vm.sortSelect,"item-text":"name","item-value":"id","outlined":"","dense":"","hide-details":"","return-object":""},on:{"input":_vm.inputSort},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex w-full py-2"},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(item.type === 'ASC' ? _vm.icons.mdiSortAscending : _vm.icons.mdiSortDescending)+" ")]),_c('span',[_vm._v(_vm._s(item.name))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex w-full py-2"},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(item.type === 'ASC' ? _vm.icons.mdiSortAscending : _vm.icons.mdiSortDescending)+" ")]),_c('span',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.state.sort.value.id),callback:function ($$v) {_vm.$set(_vm.state.sort.value, "id", $$v)},expression:"state.sort.value.id"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"2","md":"2"}},[_c(VBadge,{staticClass:"w-100 cursor-pointer",attrs:{"value":_vm.state.filterCount.value,"bordered":"","overlap":""},nativeOn:{"click":function($event){return _vm.clearFilter.apply(null, arguments)}},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])]},proxy:true}])},[_c(VBtn,{class:_vm.$vuetify.breakpoint.mdAndDown && 'mb-5',attrs:{"loading":_vm.state.prospectLoading.value,"outlined":"","block":"","large":"","color":_vm.state.filterCount.value > 0 ? 'primary' : null},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.filterProspect.show()}}},[_vm._v(" Filter "+_vm._s(_vm.state.filterCount.value ? ("(" + (_vm.state.filterCount.value) + ")") : '')+" ")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.computedProspectList ? _vm.computedProspectList : [],"items-per-page":5,"item-key":"id","disable-sort":"","disable-filtering":"","footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Jumlah data',
    },"server-items-length":_vm.computedProspectCount,"mobile-breakpoint":"0","loading":_vm.state.prospectLoading.value,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(props){return [_c('div',{staticClass:"d-flex flex-column py-2"},[_c('router-link',{attrs:{"to":{ name: 'prospect-detail', params: { id: props.item.id,isSubscribed: props.item.is_subscribed} }}},[_c('span',{staticClass:"primary--text font-medium"},[_vm._v(_vm._s(props.item.name))])]),(props.item.description)?_c('p',{staticClass:"d-inline-block text-truncate mb-0 caption",staticStyle:{"max-width":"180px"}},[_vm._v(" "+_vm._s(props.item.description)+" ")]):_vm._e()],1)]}},{key:"item.status",fn:function(props){return [_c('div',[(props.item)?_c('Chip',{attrs:{"item":props.item}}):_vm._e()],1)]}},{key:"item.customer",fn:function(props){return [_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":{ name: 'customer-detail', params: { id: props.item.customer.id} }}},[_c(VAvatar,{attrs:{"size":"32","color":"primary"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.avatarText(props.item.customer.name)))])]),_c('p',{staticClass:"mb-0 px-2 primary--text font-medium"},[_vm._v(" "+_vm._s(props.item.customer.name)+" ")])],1)]}},{key:"item.updated_at",fn:function(props){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(props.item.updated_at))])]}}],null,true)},[_c('span',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"mb-0 subtitle-2"},[_vm._v(" Dibuat : "+_vm._s(props.item.created_at)+" ")]),_c('p',{staticClass:"mb-0 subtitle-2"},[_vm._v(" Terakhir diperbarui : "+_vm._s(props.item.updated_at)+" ")])])])]}},{key:"item.created_by",fn:function(props){return [_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":{ name: 'user-detail', params: { id: props.item.created_by.id } }}},[_c(VAvatar,{attrs:{"size":"32","color":"primary"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.avatarText(("" + (props.item.created_by.name)))))])]),_c('p',{staticClass:"mb-0 px-2 primary--text font-medium"},[_vm._v(" "+_vm._s(props.item.created_by.name)+" ")])],1)]}},{key:"item.is_subscribed",fn:function(props){return [_c('div',{staticClass:"d-flex align-center"},[_c(VCheckbox,{attrs:{"input-value":props.item.is_subscribed},on:{"change":function (value){ return _vm.toggleNotification(value,props.item.id); }}})],1)]}},{key:"item.action",fn:function(props){return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"size":"20px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])]}}],null,true)},[_c(VList,[(_vm.$can('update', 'Prospect'))?_c(VListItem,{on:{"click":function($event){return _vm.$refs.taskForm.show( props.item.customer, null, props.item )}}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Buat Task")])]):_vm._e(),_c(VDivider,{staticClass:"my-2"}),_c('router-link',{attrs:{"to":{ name: 'prospect-detail', params: { id: props.item.id } }}},[_c(VListItem,[_vm._v(" Lihat Detail")])],1),_c(VDivider,{staticClass:"my-2"}),(_vm.$can('update', 'Prospect'))?_c(VListItem,{directives:[{name:"show",rawName:"v-show",value:( props.item.status.name !== 'Won' && props.item.status.name !== 'Lost'),expression:" props.item.status.name !== 'Won' && props.item.status.name !== 'Lost'"}],on:{"click":function($event){return _vm.$refs.prospectForm.update(props.item.id)}}},[_vm._v(" Edit ")]):_vm._e(),_c(VDivider,{directives:[{name:"show",rawName:"v-show",value:( props.item.status.name !== 'Won' && props.item.status.name !== 'Lost'),expression:" props.item.status.name !== 'Won' && props.item.status.name !== 'Lost'"}],staticClass:"my-2"}),(_vm.$can('del', 'Prospect'))?_c(VListItem,{on:{"click":function($event){return _vm.archiveProspectFunc(props.item.id, true)}}},[_vm._v(" Hapus ")]):_vm._e()],1)],1)]}},{key:"footer.page-text",fn:function(item){return [_vm._v(" Melihat "),(_vm.state.prospectLoading.value)?_c(VProgressCircular,{staticClass:"mx-2 mt-n1",attrs:{"indeterminate":"","size":"20"}}):_c('span',[_vm._v(" "+_vm._s(item.pageStart)+" - "+_vm._s(item.pageStop)+" ")]),_vm._v(" dari total "+_vm._s(item.itemsLength)+" data ")]}}],null,true)}),_c('ProspectForm',{ref:"prospectForm"}),_c('TaskForm',{ref:"taskForm"}),_c('FilterProspect',{ref:"filterProspect",attrs:{"filter-data":_vm.state.filter},on:{"filter":_vm.onFilter}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }