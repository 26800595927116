<template>
  <v-card
    width="400px"
    class="pa-3 pb-0"
  >
    <div class="d-flex justify-space-between">
      <span class="text-subtitle-2 font-weight-bold primary--text">Diarsipkan</span>
      <input-icon-toggle
        v-model="prospectFilter.search"
        class="ms-2"
        :icon="icons.mdiMagnify"
        placeholder="Cari pelanggan diarsipkan"
        @input="onSearch"
        @close="closeSearch"
      />
    </div>
    <div
      v-if="!loadingProspect"
      id="deletedProspect"
      v-scroll:#deletedProspect="listenScrollFetchMoreArchivedProspect"
      class="list mt-2"
    >
      <ProspectCard
        v-for="prospect in prospectList"
        :key="prospect.id"
        :prospect-data="prospect"
        :from-kanban="false"
        :deleted-prospect="true"
        @onRestore="restoreProspectFunc"
      />
      <div
        v-if="loadingProspectPagination"
        class="d-flex align-center justify-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>
    </div>
    <div
      v-if="loadingProspect"
      class="d-flex align-center justify-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>
  </v-card>
</template>

<script>
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import ProspectCard from '@/views/prospect/component/ProspectCard.vue'
import useProspect from '@/composables/useProspect'
import { apolloClient } from '@/vue-apollo'
import { archiveProspect } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

export default {
  components: {
    InputIconToggle,
    ProspectCard,
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const search = ref('')
    const isOpen = computed(() => props.isOpen)
    const {
      prospectList, listenScrollFetchMoreArchivedProspect, loadingProspect, fetchProspect, prospectFilter, loadingProspectPagination, debouncedFetchArchivedProspects,
    } = useProspect()
    onMounted(() => {
      fetchProspect(false, true)
    })
    const closeSearch = () => {
      prospectFilter.value.search = null
      fetchProspect(false, true)
    }
    watch(isOpen, () => {
      fetchProspect(false, true)
    })
    const onSearch = value => {
      prospectFilter.value.search = value
      debouncedFetchArchivedProspects()
    }
    const restoreProspectFunc = id => {
      console.log(id)
      Vue.$dialog({
        title: 'Pulihkan Prospek?',
        body: 'Yakin ingin pulihkan Prospek?',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: archiveProspect,
            fetchPolicy: 'no-cache',
            variables: {
              id,
              is_archive: false,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            emit('onUpdate')
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil memulihkan prospek!',
            })
          }).catch(err => {
            errorHandling(err)
          })
        }
      })
    }

    return {
      onSearch,
      prospectFilter,
      search,
      closeSearch,
      fetchProspect,
      prospectList,
      listenScrollFetchMoreArchivedProspect,
      loadingProspect,
      loadingProspectPagination,
      restoreProspectFunc,
      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.list {
  @include style-scroll-bar();
  max-height: 60vh;
  overflow-y: scroll;
}
</style>
