<template>
  <div>
    <v-chip
      v-if="!disableChip.includes('Won')"
      small
      :color="handleChipStyle('Won') ? 'success' : 'disabled'"
      :class="`${ handleChipStyle('Won') ? 'success--text' : 'disabled--text'} mx-1 font-weight-bold v-chip-light-bg`"
      @click="handleChip('Won')"
    >
      Won
    </v-chip>
    <v-chip
      v-if="!disableChip.includes('Lost')"
      small
      :color="handleChipStyle('Lost') ? 'error' : 'disabled'"
      :class="`${ handleChipStyle('Lost') ? 'error--text' : 'disabled--text'} mx-1 font-weight-bold v-chip-light-bg`"
      @click="handleChip('Lost')"
    >
      Lost
    </v-chip>
    <v-chip
      v-if="!disableChip.includes('Cold')"
      small
      :color="handleChipStyle('Cold') ? 'info' : 'disabled'"
      :class="`${ handleChipStyle('Cold') ? 'info--text' : 'disabled--text'} mx-1 font-weight-bold v-chip-light-bg`"
      @click="handleChip('Cold')"
    >
      Cold
    </v-chip>
    <v-chip
      v-if="!disableChip.includes('Hot')"
      small
      :color="handleChipStyle('Hot') ? 'warning' : 'disabled'"
      :class="`${ handleChipStyle('Hot') ? 'warning--text' : 'disabled--text'} mx-1 font-weight-bold v-chip-light-bg`"
      @click="handleChip('Hot')"
    >
      Hot
    </v-chip>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'ChipSelect',
  props: {
    selectMode: {
      default: 'multi',
      type: String,
    },
    disableChip: {
      default: () => [],
      type: Array,
    },
    chipStatus: {
      default: () => [],
      type: Array,
    },
  },
  setup(props, { emit }) {
    const status = ref(props.chipStatus ? props.chipStatus : [])
    const handleChipStyle = style => {
      const filter = status.value.find(c => c === style)

      return !!filter
    }
    const handleChip = data => {
      const filter = status.value.find(c => c === data)
      if (filter) {
        status.value = status.value.filter(dd => dd !== filter)
      } else {
        // eslint-disable-next-line no-lonely-if
        if (props.selectMode === 'multi') {
          status.value.push(data)
        } else {
          status.value = [data]
        }
      }
      emit('onChange', status.value)
    }
    const reset = () => {
      status.value = []
    }

    return {
      reset, status, handleChip, handleChipStyle,
    }
  },
})
</script>
