<template>
  <v-dialog
    v-model="isOpen"
    max-width="600px"
    @input="!$event ? close() : null"
  >
    <v-card
      class="rounded-b-0"
    >
      <div class="d-flex">
        <v-card-title>Filter Prospek</v-card-title>
        <v-spacer />
        <v-icon
          class="mr-4"
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <div class="pa-4">
        <v-row dense>
          <v-col class="d-flex flex-column">
            <p class="mb-2 caption">
              Waktu dibuat
            </p>
            <DateRangePicker
              :value="createdAt"
              @select="createdAtDate"
            />
          </v-col>
        </v-row>
        <v-row
          dense
          class="mb-2"
        >
          <v-col class="d-flex flex-column">
            <p class="mb-2 caption">
              Waktu diupdate
            </p>
            <DateRangePicker
              :value="updatedAt"
              @select="updatedAtDate"
            />
          </v-col>
        </v-row>
        <UserAutoSuggest
          :value="createdBy"
          class="mb-4"
          :multiple="true"
          label="Pembuat"
          @input="createdByFilter"
        />
        <UserAutoSuggest
          :value="assignedTo"
          class="mb-4"
          :multiple="true"
          label="Pengguna yang bertugas"
          @input="assignedToFilter"
        />
        <CustomerAutoSuggest
          :value="customer"
          class="mb-4"
          :multiple="true"
          label="Pelanggan"
          @input="customerFilter"
        />
        <v-row>
          <v-col>
            <p class="mb-2 caption">
              Status
            </p>
            <ChipGroup
              :multiple="true"
              :value="status"
              @input="statusChange"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="defaultSelected"
              :items="subscribeStatus"
              item-text="name"
              item-value="value"
              label="Subscribed"
              outlined
              @change="changeSubscribed"
            />
          </v-col>
        </v-row>
        <div class="d-flex flex-column flex-md-row mt-4">
          <v-btn
            class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
            outlined
            @click="resetFilter()"
          >
            Reset Filter
          </v-btn>
          <v-btn
            color="primary"
            @click="showDialogFilter = false; close()"
          >
            Filter Job
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { createFieldMapper } from 'vuex-use-fields'
import UserAutoSuggest from '@/components/inputs/UserAutoSuggest.vue'
import ChipSelect from '@/views/prospect/component/ChipSelect.vue'
import CustomerAutoSuggest from '@/components/inputs/CustomerAutoSuggest.vue'
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'
import ChipGroup from '@/views/prospect/component/ChipGroup.vue'

const useFieldProspect = createFieldMapper({ getter: 'prospect/getField', setter: 'prospect/setField' })
export default defineComponent({
  data: () => ({
    defaultSelected: {
      name: 'Semua',
      value: null,
    },
    subscribeStatus: [
      {
        name: 'Semua',
        value: null,
      },
      {
        name: 'Mengikuti',
        value: true,
      },
      {
        name: 'Tidak Mengikuti',
        value: false,
      },
    ],
  }),
  components: {
    UserAutoSuggest,
    ChipSelect,
    CustomerAutoSuggest,
    DateRangePicker,
    ChipGroup,
  },
  props: {
    filterData: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const state = {
      ...useFieldProspect(['filter']),
    }
    const chipRefs = ref()
    const isOpen = ref(false)
    const status = ref([])
    const filter = ref({})
    const assignedTo = ref([])
    const createdBy = ref([])
    const customer = ref([])
    const createdAt = ref({ startDate: null, endDate: null })
    const updatedAt = ref({ startDate: null, endDate: null })
    const isSubscribed = ref(false)

    onMounted(() => {
      status.value = props.filterData.value.status ? props.filterData.value.status : []
      assignedTo.value = props.filterData.value.assigned_to ? props.filterData.value.assigned_to : []
      createdBy.value = props.filterData.value.created_by ? props.filterData.value.created_by : []
      customer.value = props.filterData.value.customer ? props.filterData.value.customer : []

      createdAt.value.startDate = props.filterData.value.created_at ? props.filterData.value.created_at.date_min : null
      createdAt.value.endDate = props.filterData.value.created_at ? props.filterData.value.created_at.date_max : null

      updatedAt.value.startDate = props.filterData.value.updated_at ? props.filterData.value.updated_at.date_min : null
      updatedAt.value.endDate = props.filterData.value.updated_at ? props.filterData.value.updated_at.date_max : null

      isSubscribed.value = props.filterData.value.is_subscribed
    })

    const close = () => {
      console.log('status', status.value)
      let countValue = 0
      filter.value = {
        data: {
          status: status.value.length ? status.value : null,
          assigned_to: assignedTo.value.length ? assignedTo.value.map(value => value.id) : null,
          created_by: createdBy.value.length ? createdBy.value.map(value => value.id) : null,
          customer: customer.value.length ? customer.value : null,
          created_at: !createdAt.value.startDate ? null : { date_min: createdAt.value.startDate, date_max: createdAt.value.endDate },
          updated_at: !updatedAt.value.startDate ? null : { date_min: updatedAt.value.startDate, date_max: updatedAt.value.endDate },
          is_subscribed: isSubscribed.value,
        },
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filter.value.data) {
        if (filter.value.data[key]) countValue += 1
      }
      console.log(countValue)
      filter.value = { ...filter.value, count: countValue }
      emit('filter', filter.value)
      isOpen.value = false
    }
    const resetFilter = () => {
      // console.log(chipRefs)
      // chipRefs.value.reset()
      status.value = []
      filter.value = {}
      assignedTo.value = []
      createdBy.value = []
      customer.value = []
      createdAt.value = { startDate: null, endDate: null }
      updatedAt.value = { startDate: null, endDate: null }
      isSubscribed.value = null
      close()
    }
    const statusChange = data => {
      status.value = data
      console.log(data)
    }
    const show = () => {
      status.value = state.filter.value.status ?? []
      isOpen.value = true
    }
    const assignedToFilter = data => {
      assignedTo.value = data
    }
    const createdByFilter = data => {
      createdBy.value = data
    }
    const customerFilter = data => {
      customer.value = data
    }
    const updatedAtDate = data => {
      updatedAt.value = data
    }
    const createdAtDate = data => {
      createdAt.value = data
    }
    const changeSubscribed = data => {
      console.log(data)
      isSubscribed.value = data
    }

    return {
      filter,
      status,
      statusChange,
      resetFilter,
      chipRefs,
      isOpen,
      show,
      close,
      customer,
      assignedTo,
      createdBy,
      isSubscribed,
      assignedToFilter,
      createdByFilter,
      customerFilter,
      changeSubscribed,
      createdAt,
      updatedAt,
      updatedAtDate,
      createdAtDate,
      icons: {
        mdiClose,
      },
    }
  },
})
</script>
